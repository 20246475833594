import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const RezydencjaGubalowka = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Rezydencja Gubałówka",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #00425B, #014D6A, #005C80, #014D6A, #00425B )",
        icons: "#015576",
        navClass: "gubalowka",
        ogImage: require("../../assets/img/portfolio/gubalowka_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/rezydencja-gubalowka/",
      }}
    >
      <PortfolioHeader name="gubalowka" height="135" />
      <section className="container-fluid gubalowka_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Rezydencja Gubałówka</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Naszym zadaniem było stworzenie atrakcyjnego projektu
                wspierającego sprzedaż i prezentującego ofertę inwestycyjną
                kupna luksusowego apartamentu w Rezydencji Gubałówka. Projekt
                miał wywołać określone emocje i wzmóc pragnienie dokonania
                inwestycji, poprzez podkreślenie prestiżu miejsca i atrakcyjnych
                warunków współpracy. Strona miała być spójna wizualnie z
                istniejącą już identyfikacją wizualną obiektu – w ten sposób
                tworzymy spójny wizerunek Klienta, umacniamy komunikaty w
                świadomości odbiorców i tworzymy powiązania między wszystkimi
                elementami promocji.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid gubalowka_section_3">
        <div className="row align-items-center row_1">
          <div className="col-md-6">
            <p>
              Stworzyliśmy Landing Page mający na celu wsparcie sprzedaży
              inwestycji - rozbudowy istniejącego i działającego już od 2 lat
              obiektu hotelowego w samym sercu Zakopanego. Naszym celem było
              pokazanie nie tylko potencjalnego zysku inwestora, ale także
              atrakcyjność samej lokalizacji obiektu na Gubałówce.
            </p>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/gubalowka_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-7">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/gubalowka_main_1.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-7 offset-md-5 text-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/gubalowka_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters align-items-end row_4">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/gubalowka_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p>
              W oparciu o stworzoną przez nas stronę oraz komplet banerów
              marketingowych prowadzimy szeroko zakrojoną kampanię marketingową
              w mediach społecznościowych tj. Facebook oraz Google, uzyskując
              doskonałe wyniki przekładające się na bardzo wysoką ilość leadów
              oraz rekordowo niski koszt ich pozyskania, a co za tym idzie pełne
              zadowolenie naszego Klienta, który już przymierza się do
              umocnienia naszej współpracy i wspólną realizację kolejnych
              projektów deweloperskich.
            </p>
          </div>
        </div>
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default RezydencjaGubalowka;
